import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {clarity} from "react-microsoft-clarity";
import { init } from '@amplitude/analytics-browser';
const root = ReactDOM.createRoot(document.getElementById('root'));

clarity.init(process.env.REACT_APP_CLARITY_ID);
clarity.consent();

init(process.env.REACT_APP_AMPLITUDE_KEY, {
    defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
    },
});

root.render(
    <App />
);
