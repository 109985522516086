import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getSubscriptionStatus } from '../utils/apis/getSubscriptionStatus';
import Header from '../components/Header';
import Footer from '../components/Footer';
import * as amplitude from "@amplitude/analytics-browser";

const PaymentStatus = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('checking');
    const maxAttempts = 10;
    const intervalTime = 10000;

    useEffect(() => {
        let attempts = 0;

        const checkStatus = async () => {
            if (attempts < maxAttempts) {
                const result = await getSubscriptionStatus(id);
                if (result.success) {
                    if (result.data.status === 'active') {
                        setStatus('active');
                        toast.success('payment successful!');
                        amplitude.track('Payment Check Result Success',{
                            subscriptionId: id
                        });
                        setTimeout(() => {
                            navigate('/home');
                        }, 2000);
                        return;
                    }
                } else {
                    toast.error('failed to check payment status');
                    amplitude.track('Payment Check Failure',{
                        subscriptionId: id
                    });
                }
                attempts += 1;
                setProgress((attempts / maxAttempts) * 100);
                setTimeout(checkStatus, intervalTime);
            } else {
                setStatus('failed');
                toast.error('payment failed');
                amplitude.track('Payment Check Result Failure',{
                    subscriptionId: id
                });
            }
        };

        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + (100 / maxAttempts) : 100));
        }, 1000);

        checkStatus();

        return () => {
            clearTimeout(checkStatus);
            clearInterval(interval);
        };
    }, [id, navigate]);

    return (
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow flex flex-col items-center justify-center p-4">
                {status === 'checking' && (
                    <>
                        <div className="w-24 h-24 mb-4">
                            <CircularProgressbar
                                value={progress}
                                text={`${Math.round(progress)}%`}
                                styles={buildStyles({
                                    textColor: '#000',
                                    pathColor: '#000',
                                    trailColor: '#d6d6d6'
                                })}
                            />
                        </div>
                        <p className="text-lg text-black">Checking payment status...</p>
                    </>
                )}
                {status === 'failed' && (
                    <>
                        <p className="text-lg text-red-500">Payment failed. Please try again.</p>
                        <button className="btn mt-4" onClick={() => navigate('/plans')}>Retry</button>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default PaymentStatus;